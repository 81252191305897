<template>
  <div class="tabberWarp">
    <div class="warp">
      <template v-for="(item, index) in tabbarDes">
        <!-- @change="getVal" -->
        <Item :txt="item.txt" :page="item.page" :key="index" :sel="selected">
          <img :src="item.normalImg" slot="normalImg" />
          <img :src="item.activeImg" slot="activeImg" />
        </Item>
      </template>
    </div>
  </div>
</template>

<script>
import Item from "./item";
import { getToken } from "@/utils/auth";
import { getInfo } from "../../api/ThisInfo.js";
export default {
  components: {
    Item,
  },
  props: {
    selected: {
      type: String,
    },
  },
  data() {
    return {
      tabbarDes: [
		  
      ],
    };
  },
  created() {
    // 获取权限
    if (getToken()) {
      getInfo().then((res) => {
        // 从新获取权限判断入口权限存放到VueX
        if (res.data.permission_info[0] != 0) {
			if(this.tabbarDes.length == 4){
				return
			}else{
				this.tabbarDes = [
					{
						txt: "首页",
						page: "",
						normalImg: require("../../assets/icon/home.png"),
						activeImg: require("../../assets/icon/ChangeHome.png"),
					},
					{
						txt: "比赛",
						page: "CompetiH5",
						normalImg: require("../../assets/icon/Competi.png"),
						activeImg: require("../../assets/icon/ChangeCompeti.png"),
					},
					{
						txt: "管理",
						page: "ManagementCenterH5",
						normalImg: require("../../assets/icon/ManagementCenter.png"),
						activeImg: require("../../assets/icon/ChangeManagementCenter.png"),
					},
					{
						txt: "我的",
						page: "PersonalInfoH5",
						normalImg: require("../../assets/icon/PersonalInfo.png"),
						activeImg: require("../../assets/icon/ChangePersonalInfo.png"),
					},
					];
			}
        } else {
			if(this.tabbarDes.length == 3){
				return
			}else{
				this.tabbarDes = [
              {
                txt: "首页",
                page: "",
                normalImg: require("../../assets/icon/home.png"),
                activeImg: require("../../assets/icon/ChangeHome.png"),
              },
              {
                txt: "比赛",
                page: "CompetiH5",
                normalImg: require("../../assets/icon/Competi.png"),
                activeImg: require("../../assets/icon/ChangeCompeti.png"),
              },
              {
                txt: "我的",
                page: "PersonalInfoH5",
                normalImg: require("../../assets/icon/PersonalInfo.png"),
                activeImg: require("../../assets/icon/ChangePersonalInfo.png"),
              },
            ];
			}
        }
      });
    }else{
		this.tabbarDes = [
              {
                txt: "首页",
                page: "",
                normalImg: require("../../assets/icon/home.png"),
                activeImg: require("../../assets/icon/ChangeHome.png"),
              },
              {
                txt: "比赛",
                page: "CompetiH5",
                normalImg: require("../../assets/icon/Competi.png"),
                activeImg: require("../../assets/icon/ChangeCompeti.png"),
              },
              {
                txt: "我的",
                page: "PersonalInfoH5",
                normalImg: require("../../assets/icon/PersonalInfo.png"),
                activeImg: require("../../assets/icon/ChangePersonalInfo.png"),
              },
            ];
	}
  },
  methods: {
    // getVal:function(res){
    // 	this.selected = res;
    // }
  },
};
</script>

<style lang="scss" scoped>
.tabberWarp {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 54px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-evenly;
  background: #fff;
  .warp {
    width: 100%;
    border-top: 1px solid #eee;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0;
  }
  .warp img {
    width: 20px;
    height: 20px;
  }
}
</style>